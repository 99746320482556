export const referencesList_pl = {
  modernizationWorks: [
    {
      id: "mw45_pl",
      year: "2023 - 2024",
      status: "Zakończono",
      investor: "Veolia Energia Łódź S.A.",
      site: "Elektrociepłownia EC-3",
      unit: "Kocioł olejowy PTWM-100 (K8)",
      description_short:
        "Modernizacja kotła PTWM-100 w EC-3 ",
      description_long: "no",
      icons: "Olej opałowy lekki Kotłownia",
    },
    {
      id: "mw44_pl",
      year: "2023 - 2024",
      status: "Zakończono",
      investor: "Veolia Energia Łódź S.A.",
      site: "Elektrociepłownia EC-3",
      unit: "Rozładunek i transport oleju lekkiego do kotła PTWM-100",
      description_short:
        "Przebudowa mazutowni celem dostosowania urządzeń do rozładunku i transportu oleju lekkiego dla kotła PTWM-100",
      description_long: "no",
      icons: "Olej opałowy lekki Mazutownia",
    },
    {
      id: "mw43_pl",
      year: "2021 - 2024",
      status: "Zakończono",
      investor: "PGE Energia Ciepła S.A.",
      site: "Elektrociepłownia Bydgoszcz",
      unit: "Kotły gazowe 4x 10 MW",
      description_short:
        "Kompleksowe wykonawstwo ciepłowni wodnej gazowej o mocy do 40 MWt ",
      description_long: "no",
      icons: "Gaz Kotłownia",
    },
    {
      id: "mw42_pl",
      year: "2021 - 2022",
      status: "Zakończono",
      investor: "Węglokoks Energia ZCP",
      site: "Elektrociepłownia Mikołaj",
      unit: "Kotłownia gazowa 2x 11 MW",
      description_short: "Kompleksowe wykonawstwo szczytowej kotłowni gazowej",
      description_long: "no",
      icons: "Gaz Wodór Kotłownia",
    },
    {
      id: "mw41_pl",
      year: "2021",
      status: "W trakcie",
      investor: "PGE Energia Ciepła S.A.",
      site: "Elektrociepłownia Gdyńska",
      unit: "3 kotły gazowo olejowo (90 MWt)",
      description_short:
        "Budowa nowej kotłowni (gazowo-olejowej) rezerwowo szczytowej KRS II o mocy cieplnej 90 MWt",
      description_long: "no",
      icons: "Gaz Wodór Kotłownia",
    },
    {
      id: "mw40_pl",
      year: "2021",
      status: "W trakcie",
      investor: "PGE Energia Ciepła S.A.",
      site: "Elektrociepłownia Gdyńska",
      unit: "Kocioł olejowy 26 MWt",
      description_short:
        "Zainstalowanie w istniejącym budynku kotła parowego opalanego olejem opałowym lekkim",
      description_long: "no",
      icons: "Gaz Wodór Kotłownia",
    },
    {
      id: "mw39_pl",
      year: "2020 - 2023",
      status: "Zakończono",
      investor: "PCC Rokita S.A.",
      site: "Brzeg Dolny",
      unit: "Kocioł gazowy 10 MW",
      description_short:
        "Kompleksowe wykonawstwo kotłowni gazowej (paliwo wodorowe)",
      description_long: "no",
      icons: "Gaz Wodór Kotłownia",
    },
    {
      id: "mw38_pl",
      year: "2020 - 2022",
      status: "Zakończono",
      investor: "Grupa Azoty S.A",
      site: "Zakłady Chemiczne Tarnów",
      unit: "Kocioł gazowy TP-170G (K1)",
      description_short: "Wykonanie modernizacji kotła gazowego K1",
      description_long: "no",
      icons: "Gaz Metoda pierwotna",
    },
    {
      id: "mw37_pl",
      year: "2020 - 2022",
      status: "Zakończono",
      investor: "Energa Kogeneracja Sp. z o.o.",
      site: "Elektrociepłownia Elbląg",
      unit: "3 kotły gazowe 38 MWt",
      description_short: "Kompleksowe wykonawstwo kotłowni gazowej",
      description_long: "no",
      icons: "Gaz Kotłownia",
    },
    {
      id: "mw36_pl",
      year: "2019 - 2021",
      status: "Zakończono",
      investor: "PGE EC",
      site: "Elektrociepłownia Gdańsk",
      unit: "Kocioł WP-70 (K4) i WP-120 (K6)",
      description_short:
        "Modernizacja instalacji paleniskowych kotłów wodnych dla ograniczenia stężenia NOx do 450 mg/Nm3",
      description_long: "no",
      icons: "Metoda pierwotna",
    },
    {
      id: "mw35_pl",
      year: "2019 - 2021",
      status: "Zakończono",
      investor: "PGE EC",
      site: "Elektrociepłownia Wrocław",
      unit: "Kocioł WP-120 (K3) i WP-120 (K5)",
      description_short:
        "Modernizacja instalacji paleniskowych kotłów wodnych dla ograniczenia stężenia NOx do 450 mg/Nm3",
      description_long: "no",
      icons: "Metoda pierwotna",
    },
    {
      id: "mw34_pl",
      year: "2019 - 2021",
      status: "Zakończono",
      investor: "TAURON Wytwarzanie S.A.",
      site: "Elektrownia Jaworzno III",
      unit: "Blok nr 1, 3 i 5 (Kotły Op-650)",
      description_short:
        "Wykonanie instalacji odazotowania spalin SCR dla bloków nr 1, 3 i 5. Poziom emisji NOx <65mg/Nm3",
      description_long: "no",
      icons: "SCR",
    },
    {
      id: "mw33_pl",
      year: "2018 - 2021",
      status: "Zakończono",
      investor: "VEOLIA Energia Łódź S.A.",
      site: "Elektrociepłownia EC4 Łódź",
      unit: "Kocioł OP-130 (K1 i K2), OP-230 (K3)",
      description_short:
        "Wykonanie instalacji SCR w drugim ciągu kotła. Poziom emisji NOx <135mg/Nm3",
      description_long: "no",
      icons: "SCR SNCR Hybryda",
    },
    {
      id: "mw32_pl",
      year: "2018 - 2021",
      status: "Zakończono",
      investor: "VEOLIA Energia Łódź S.A.",
      site: "Elektrociepłownia EC4 Łódź",
      unit: "Kocioł OP-230 (K3), OP-430 (K7)",
      description_short:
        "Wykonanie instalacji SCR w drugim ciągu kotła. Poziom emisji NOx <135mg/Nm3",
      description_long: "no",
      icons: "SCR SNCR Hybryda",
    },
    {
      id: "mw31_pl",
      year: "2019-2020",
      status: "Zakończono",
      investor: "PEC Gliwice sp. z o.o. ",
      site: "PEC Gliwice",
      unit: "Kocioł WP70 nr 2  81,4 MWt ",
      description_short:
        "Budowa instalacji odazotowania spalin kotła WP-70 nr 2 metoda niekatalityczna SNCR",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw30_pl",
      year: "2018-2019 ",
      status: "Zakończono",
      investor: "PEC Gliwice sp. z o.o. ",
      site: "PEC Gliwice",
      unit: "Kocioł WP70 nr 2  81,4 MWt ",
      description_short:
        "Remont i wymiana kompletnej części ciśnieniowej kotła WP-70",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw29_pl",
      year: "2018-2019 ",
      status: "Zakończono",
      investor: "VEOLIA Energia Łódź S.A.",
      site: "Elektrociepłownia EC4 Łódź",
      unit: "Kocioł WP120 nr 6 ",
      description_short:
        "Zabudowa instalacji deNOx obniżająca emisję tlenków azotu do poziomu NOx  < 430 mg/Nm3",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw28_pl",
      year: "2017- 2018 ",
      status: "Zakończono",
      investor: "Energetyka sp. z o.o. Lublin",
      site: "Elektrociepłownia EC3 Głogów",
      unit: "2 KOTŁY GAZOWE 33 MWt ",
      description_short:
        "Rozbudowa mocy w oparciu o kotły gazowe o mocy  ok. 60 MWt",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw27_pl",
      year: "2017-2019 ",
      status: "Zakończono",
      investor: "PEC Gliwice sp. z o.o. ",
      site: "PEC Gliwice",
      unit: "Kocioł WP70 nr 1 i 3 81,4 MWt",
      description_short:
        "Budowa instalacji odazotowania spalin kotła WP-70 nr 1 i nr 3 metoda niekatalityczna SNCR",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw26_pl",
      year: "2016-2017 ",
      status: "Zakończono",
      investor: "PGE Energia Ciepła S.A.",
      site: "Elektrownia Rybnik ",
      unit: "Kocioł OP-650 (K3 225 MW, K5 215 MW, K6 215 MW)",
      description_short:
        "Modernizacja kotła OP-650k nr 3, 5, 6 pod kątem obniżenia poziomu NOx do poziomu < 200  mg/Nm3",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw25_pl",
      year: "2016-2018 ",
      status: "Zakończono",
      investor: "Elektrociepłownia AMP Kraków",
      site: "Elektrociepłownia AMP Kraków",
      unit: "Kocioł OP-230 nr 8 ",
      description_short:
        "Przebudowa kotła OP-230 nr 8 w celu zmniejszenia emisji zanieczyszczeń gazowych i pyłowych",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw24_pl",
      year: "2016-2017 ",
      status: "Zakończono",
      investor: "Grupa Azoty S.A",
      site: "Elektrociepłownia ECII Tarnów",
      unit: "Kocioł OP-230 nr 5  160 MW ",
      description_short:
        "Zabudowa technologii redukcji tlenków azotu metodami pierwotny dla osiągnięcia emisji NOx <190 Mg/Nm3",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw23_pl",
      year: "2015 -2019 ",
      status: "Zakończono",
      investor: "VEOLIA Energia Łódź S.A.",
      site: "Elektrociepłownia EC4 Łódź",
      unit: "Kocioł OP-230 nr 2 ",
      description_short:
        "Modernizacja układu paleniskowego celem redukcji emisji NOx  < 190 mg/Nm3",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw22_pl",
      year: "2015 -2019",
      status: "Zakończono",
      investor: "VEOLIA Energia Łódź S.A.",
      site: "Elektrociepłownia EC3 Łódź",
      unit: "Kocioł OP-130 nr 1,2  Kocioł OP-230 nr 3  ",
      description_short:
        "Modernizacja układu paleniskowego celem redukcji emisji NOx  < 190 mg/Nm3",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw21_pl",
      year: "2015-2018 ",
      status: "Zakończono",
      investor: "PGE Energia Ciepła S.A.",
      site: "Elektrownia Rybnik ",
      unit: "Kotły OP-650 (225 MWe K3 215 MWe K5 215 MWe K6)",
      description_short:
        "Modernizacja kotła pod kątem obniżenia poziomu NOx do poziomu < 200 mg/Nm3",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw20_pl",
      year: "2015-2016 ",
      status: "Zakończono",
      investor: "PGE Energia Ciepła S.A.",
      site: "Elektrownia Rybnik ",
      unit: "Kotły OP-650 (215 MWe K5 225 MWe K8)",
      description_short:
        " Modernizacja instalacji paleniskowej kotła dla osiągnięcia poziomu emisji NOx ≤ 300 mg/Nm3",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw19_pl",
      year: "2015-2016",
      status: "Zakończono",
      investor: "PGE Energia Ciepła S.A.",
      site: "Elektrownia Rybnik ",
      unit: "Kocioł OP-650 nr 3 225 MW ",
      description_short:
        " Modernizacja instalacji paleniskowej kotła dla osiągnięcia poziomu emisji NOx ≤ 300 mg/Nm3",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw18_pl",
      year: "2014",
      status: "Zakończono",
      investor: "PEC Gliwice sp. z o.o. ",
      site: "PEC GLIWICE ",
      unit: "Kocioł WP-70 nr 3 81,4 MWt ",
      description_short: "Remont instalacji odazotowania spalin kotła",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw17_pl",
      year: "2013",
      status: "Zakończono",
      investor: "PGE Górnictwo i Energetyka Konwencjonalna",
      site: "Elektrownia Dolna Odra ",
      unit: "Kocioł OP-650 nr 5 i 8 222 MW K5 232 MW K8 ",
      description_short:
        "Zabudowanie kompletnych instalacji odazotowania spalin w celu obniżenia emisji NOx do poziomu ≤190  mg/Nm3",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw16_pl",
      year: "2014-2015 ",
      status: "Zakończono",
      investor: "TAURON Wytwarzanie S.A.",
      site: "Elektrownia Jaworzno III",
      unit: "Kotły CFB 260 ",
      description_short:
        "Dostosowanie kotłów CFB 260 do spełnienia wymogów norm emisyjnych. ",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw15_pl",
      year: "2013",
      status: "Zakończono",
      investor: "PGE Górnictwo i Energetyka Konwencjonalna",
      site: "Elektrownia Dolna Odra ",
      unit: "Kocioł OP-650 ",
      description_short:
        "Modernizacjaseparatorów pyłu młynów węglowych typu MKM25",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw14_pl",
      year: "2013",
      status: "Zakończono",
      investor: "Gmina Lubusko",
      site: "Oczyszczalnia Ścieków w Lubsku",
      unit: "-",
      description_short:
        "Prace budowlane, dostawy i uruchomienie oczyszczalni ścieków",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw13_pl",
      year: "2013",
      status: "Zakończono",
      investor: "PGNiG Termika S.A. ",
      site: "Elektrociepłownia Siekierki, Warszawa ",
      unit: "Kotły WP-120 nr 5, 6 i 7 WP-200, K-16 ",
      description_short: "Modernizacja układu paleniskowego kotłów WP-120",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw12_pl",
      year: "2013 - 2015 ",
      status: "Zakończono",
      investor: "Dalkia Łódź S.A.",
      site: "Elektrociepłownia Łódź",
      unit: "Kocioł OP-430K nr 7 ",
      description_short:
        "Modernizacja układu spalania kotła obniżająca emisję tlenków azotu do poziomu NOx  < 190 mg/Nm3",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw11_pl",
      year: "2013-2017",
      status: "Zakończono",
      investor: "Zakłady Chemiczne Police S.A.       ",
      site: "Elektrociepłownia Police II",
      unit: "Kocioł OP-230 nr 1 i 2  ",
      description_short:
        "Modernizacja kotła obniżająca emisję tlenków azotu do poziomu NOx  < 190 mg/Nm3",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw10_pl",
      year: "2012-2013 ",
      status: "Zakończono",
      investor: "PGE Górnictwo i Energetyka Konwencjonalna",
      site: "Elektrownia Dolna Odra ",
      unit: "Kocioł OP-650 nr 6 i 7 225 MW K6 232 MW K7 ",
      description_short:
        "Modernizacja kotłów obniżająca emisję tlenków azotu do poziomu NOx  < 190 mg/Nm3",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw09_pl",
      year: "2012-2015 ",
      status: "Zakończono",
      investor: "PGE Energia Ciepła S.A.",
      site: "Elektrownia Rybnik ",
      unit: "Kocioł OP-650 nr 5 i 8  215 MW K5 225 MW K8  ",
      description_short:
        "Modernizacja instalacji paleniskowych kotłów dla osiągnięcia  emisji NOx<300 mg/Nm3",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw08_pl",
      year: "2012",
      status: "Zakończono",
      investor: "Enea Wytwarzanie S.A.",
      site: "Elektrownia Kozienice ",
      unit: "Kocioł OP-650 nr 3 223 MW ",
      description_short:
        "Modernizacja kotła celem obniżenia emisji NOx do poziomu < 300 mg/Nm3",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw07_pl",
      year: "2011",
      status: "Zakończono",
      investor: "PGE Energia Ciepła S.A.",
      site: "Elektrociepłownia  Gdańsk ",
      unit: "Kocioł OP-230 nr 10 165 MW ",
      description_short:
        "Kompleksowa modernizacja instalacji paleniskowej kotła K10",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw06_pl",
      year: "2011",
      status: "Zakończono",
      investor: "Grupa GDF Suez Energia Polska",
      site: "Elektrownia Połaniec ",
      unit: "Kocioł EP-650 nr 5 225 MW ",
      description_short:
        "Modernizacja instalacji paleniskowej kotła w celu uzyskania emisji NOx < 200 mg/Nm3",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw05_pl",
      year: "2011",
      status: "Zakończono",
      investor: "PGE Energia Ciepła S.A.",
      site: "Elektrownia Rybnik",
      unit: "Kocioł OP-650 (K7) 212 MWe",
      description_short:
        "Modernizacja instalacji paleniskowej kotła nr 7 dla osiągnięcia poziomu emisji NOx ≤ 300 mg/Nm3",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw04_pl",
      year: "2010",
      status: "Zakończono",
      investor: "Alstom Power sp. z o.o. ",
      site: "Elektrownia Bełchatów",
      unit: "Kocioł BB-2400 (Blok 860 MW)",
      description_short:
        "Projekt, dostawa instalacji pomocniczej i wykonanie procesu dmuchania",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw03_pl",
      year: "2010",
      status: "Zakończono",
      investor: "Grupa GDF Suez Energia Polska",
      site: "Elektrownia Połaniec",
      unit: "Kocioł OP-650 (K8)",
      description_short:
        "Zastosowanie nowej technologii spalania przy redukcji emisji NOx <200 mg/ Nm3",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw02_pl",
      year: "2010",
      status: "Zakończono",
      investor: "Energa Kogeneracja sp. z o.o.",
      site: "Elektrociepłownia Elbląg",
      unit: "Blok 20MW na biomasę",
      description_short:
        "Inżynier Kontraktu w realizacji projektu budowy bloku na biomasę o mocy 20 MW",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw01_pl",
      year: "2010",
      status: "Zakończono",
      investor: "PGE Energia Ciepła S.A.",
      site: "Elektrownia Rybnik",
      unit: "Kocioł OP-650 (K6) 215 MW",
      description_short:
        "Modernizacja instalacji paleniskowej kotła nr 6 dla osiągnięcia poziomu emisji NOx ≤ 300",
      description_long: "no",
      icons: "",
    },
  ],
  commissioningWorks: [
    {
      id: "cw42_pl",
      year: "2022",
      status: "W trakcie",
      investor: "Primue Energoserwis",
      site: "MEGATEM EC-Lublin",
      unit: "Kocioł 45,6 MW / Turbina",
      description_short:
        "Opracowanie dokumentacji rozruchowej oraz wykonanie rozruchu bloku opalanego gazem koksowniczym",
      description_long: "",
      icons: "",
    },
    {
      id: "cw41_pl",
      year: "2022",
      status: "W trakcie",
      investor: "RAFAKO S.A.",
      site: "JSW KOKS S.A.",
      unit: "Elektrociepłownia Radlin",
      description_short:
        "Opracowanie dokumentacji rozruchowej oraz wykonanie rozruchu bloku opalanego gazem koksowniczym",
      description_long: "",
      icons: "",
    },
    {
      id: "cw40_pl",
      year: "2022",
      status: "Zakończono",
      investor: "SEFAKO",
      site: "Zakłady Azotowe Puławy",
      unit: "Kocioł OP-215",
      description_short:
        "Koordynacja końca montażu kotła we wszystkich branżach",
      description_long: "",
      icons: "",
    },
    {
      id: "cw39_pl",
      year: "2022",
      status: "Zakończono",
      investor: "Gaz-System S.A.",
      site: "Goleniów",
      unit: "Tłocznia gazu",
      description_short: "Rola eksperta ds. rozruchu",
      description_long: "",
      icons: "",
    },
    {
      id: "cw38_pl",
      year: "2022",
      status: "W trakcie",
      investor: "Gaz-System S.A.",
      site: "Odolanów",
      unit: "Tłocznia gazu",
      description_short: "Rola eksperta ds. rozruchu",
      description_long: "",
      icons: "",
    },
    {
      id: "cw37_pl",
      year: "2022",
      status: "W trakcie",
      investor: "SGS Polska",
      site: "Gustorzyn",
      unit: "Tłocznia gazu",
      description_short:
        "Wykonanie nadzoru nad rozruchem tłoczni, wsparcie techniczne i eksploatacyjne",
      description_long: "",
      icons: "",
    },
    {
      id: "cw36_pl",
      year: "2022",
      status: "Zakończono",
      investor: "MAX STREICHER SPA",
      site: "Gustorzyn",
      unit: "Tłocznia gazu",
      description_short:
        "Nadzór Inwestorski w trakcie budowy tłoczni, montażu armatury w branży mechanicznej, automatyki i systemu sterowania",
      description_long: "",
      icons: "",
    },
    {
      id: "cw35_pl",
      year: "2022",
      status: "Zakończono",
      investor: "Gas Control Polska",
      site: "Kędzierzyn Koźle",
      unit: "Tłocznia gazu",
      description_short:
        "Wsparcie Nadzoru Inwestorskiego, koordynacja prac rozruchowych i przeprowadzenie rozruchu",
      description_long: "",
      icons: "",
    },
    {
      id: "cw34_pl",
      year: "2021",
      status: "W trakcie",
      investor: "Geotermia Toruń",
      site: "Toruń",
      unit: "Ciepłownia Geotermalna",
      description_short:
        "Specjalistyczne i kompleksowe  usługi  eksploatacyjne  Ciepłowni Geotermalnej wraz z Infrastrukturą towarzyszącą",
      description_long: "",
      icons: "",
    },
    {
      id: "cw33_pl",
      year: "2021",
      status: "W trakcie",
      investor: "Nowe Jaworzno Grupa TAURON",
      site: "Elektrownia Jaworzno III",
      unit: "Blok 910 MW",
      description_short:
        "Świadczenie specjalistycznych usług eksploatacyjnych i koordynacyjnych w Okresie Przejściowym Bloku 910",
      description_long: "",
      icons: "",
    },
    {
      id: "cw32_pl",
      year: "2018",
      status: "W trakcie",
      investor: "E003B7 Sp. z o.o. Racibórz",
      site: "Elektrownia Jaworzno III",
      unit: "Blok 910 MW",
      description_short: "Kompleksowy rozruch Bloku 910 MW",
      description_long: "",
      icons: "",
    },
    {
      id: "cw31_pl",
      year: "2015 - 2016",
      status: "Zakończono",
      investor: "EDF Polska",
      site: "Elektrownia Rybnik",
      unit: "Kocioł OP-650 (K8)",
      description_short: "Kompleksowy rozruch instalacji po modernizacji koła",
      description_long: "",
      icons: "",
    },
    {
      id: "cw30_pl",
      year: "2015",
      status: "Zakończono",
      investor: "Siemens",
      site: "Elektrociepłownia Gorzów",
      unit: "Blok gazowo-parowy (140 MW)",
      description_short:
        "Przeprowadzenie kompleksowego rozruchu Bloku Parowo-Gazowego 1x 140 MW",
      description_long: "",
      icons: "",
    },
    {
      id: "cw29_pl",
      year: "2014 - 2017",
      status: "Zakończono",
      investor: "SNC LAVALIN",
      site: "Anwil Włocławek",
      unit: "Blok gazowo-parowy (460 MW)",
      description_short:
        "Przeprowadzenie rozruchu Bloku Gazowo-Parowego wraz z czyszczeniem chemicznym oraz dmuchaniem kotła HRSG i rurociągów technologicznych.",
      description_long: "",
      icons: "",
    },
    {
      id: "cw28_pl",
      year: "2014 - 2016",
      status: "Zakończono",
      investor: "Rafako S.A.",
      site: "GDF SUEZ Elektrownia Połaniec",
      unit: "Kocioł OP-650 (K6)",
      description_short:
        "Rozruch instalacji katalitycznego odazotowania spalin dla kotła OP-650 nr 6.",
      description_long: "",
      icons: "",
    },
    {
      id: "cw27_pl",
      year: "2014 - 2016",
      status: "Zakończono",
      investor: "Fluor S.A",
      site: "BASF Catalyst Plant Środa Śląska",
      unit: "-",
      description_short:
        "Udział w rozruchu projektu „BASF Catalyst Plant”  Środa Śląska.",
      description_long: "no",
      icons: "",
    },
    {
      id: "cw26_pl",
      year: "2014",
      status: "Zakończono",
      investor: "Energomontaż Zachód Wrocław",
      site: "Energa Elektrownia Ostrołęka",
      unit: "Turbina 13K200",
      description_short:
        "Rozruch źródła ciepła polegającego na uciepłownieniu turbiny 13K200",
      description_long: "no",
      icons: "",
    },
    {
      id: "cw25_pl",
      year: "2013",
      status: "Zakończono",
      investor: "Fabryka Kotłów Sefako S.A. ",
      site: "PCC Rokita Brzeg Dolny",
      unit: "Kocioł OR-45 K1 i K2",
      description_short:
        "Kompleksowy rozruch technologiczny kotła K-1 i K-2 wraz z urządzeniami pomocniczymi",
      description_long: "no",
      icons: "",
    },
    {
      id: "cw24_pl",
      year: "2013 - 2014",
      status: "Zakończono",
      investor: "Rafako S.A. ",
      site: "Energa Elektrownia Kozienice",
      unit: "Kocioł Op-650 Nr 6",
      description_short:
        "Rozruch instalacji  blokowej oraz  kompletnej instalacji SCR wraz ze stacją DRiM",
      description_long: "no",
      icons: "",
    },
    {
      id: "cw23_pl",
      year: "2013 - 2015",
      status: "Zakończono",
      investor: "PGNiG Warszawa ",
      site: "Podziemny magazyn Wierzchowice",
      unit: "Magazyn gazu o pojemności 1200 m3",
      description_short:
        "Wykonanie zastępcze - dokończenie inwestycji budowy Podziemnego Magazynu Gazu",
      description_long: "no",
      icons: "",
    },
    {
      id: "cw22_pl",
      year: "2013",
      status: "Zakończono",
      investor: "Gmina Lubsko ",
      site: "Oczyszczalnia Ścieków w Lubsku",
      unit: "-",
      description_short:
        "Prace budowlane, dostawy i uruchomienie oczyszczalni ścieków. ",
      description_long: "no",
      icons: "",
    },
    {
      id: "cw21_pl",
      year: "2012",
      status: "Zakończono",
      investor: "Tauron Wytwarzanie ",
      site: "Elektrownia Jaworzno III",
      unit: "OZE 50 MW",
      description_short:
        "Kompleksowy rozruch Jednostki Wytwórczej OZE o mocy 50 MW. ",
      description_long: "no",
      icons: "",
    },
    {
      id: "cw20_pl",
      year: "2012",
      status: "Zakończono",
      investor: "ZRE Katowice ",
      site: "Elektrownia Jaworzno II",
      unit: "TG-1 50 MW",
      description_short:
        "Rozruch turbiny TG-1 o mocy 50 MW w Tauron Wytwarzanie – El. Jaworzno II. ",
      description_long: "no",
      icons: "",
    },
    {
      id: "cw19_pl",
      year: "2012",
      status: "Zakończono",
      investor: "Rafako S.A. ",
      site: "Elektrownia Jaworzno II",
      unit: "Blok 50 MW Kocioł CFB/OFZ-201",
      description_short:
        "Nadzór nad końcowym montażem urządzeń oraz rozruch kotła w zakresie zimnej i gorącej fazy",
      description_long: "no",
      icons: "",
    },
    {
      id: "cw18_pl",
      year: "2012 - 2013",
      status: "Zakończono",
      investor: "Foster Wheeler ",
      site: "Elektrownia Połaniec",
      unit: "-",
      description_short:
        "Pełnienie funkcji Kierownika Zmiany w rozruchu kotła na biomasę. ",
      description_long: "no",
      icons: "",
    },
    {
      id: "cw17_pl",
      year: "2012 - 2013",
      status: "Zakończono",
      investor: "Tauron Wytwarzanie ",
      site: "Elektrociepłownia Bielsko-Biała (Ec1)",
      unit: "Blok BC-50",
      description_short:
        "Koordynacja rozruchu bloku BC-50 w Tauron Wytwarzanie EC Bielsko- Biała. ",
      description_long: "no",
      icons: "",
    },
    {
      id: "cw16_pl",
      year: "2011 - 2012",
      status: "Zakończono",
      investor: "Tecnimont S.P.A Oddział W Polsce",
      site: "Podziemny magazyn Wierzchowice",
      unit: "Magazyn gazu o pojemności 1200 m3",
      description_short:
        "Udział w rozruchu części energetycznej Podziemnego Magazynu Gazu Wierzchowice",
      description_long: "no",
      icons: "",
    },
    {
      id: "cw15_pl",
      year: "2012",
      status: "Zakończono",
      investor: "Grupa GDF Suez Energia Polska ",
      site: "Elektrownia Połaniec",
      unit: "Zielony Blok Nr 8/205 MW Kocioł CFB-447 ",
      description_short:
        "Kompleksowa koordynacja rozruchu ZIELONEGO BLOKU o mocy 205 MW nr 8 i kotła CFB-447 MW",
      description_long: "no",
      icons: "",
    },
    {
      id: "cw14_pl",
      year: "2011 - 2012",
      status: "Zakończono",
      investor: "PBG S.A. ",
      site: "Podziemny Magazyn Gazu Wierzchowice",
      unit: "Magazyn o pojemności 1200 m3",
      description_short:
        "Koordynacja i kierowanie rozruchem Podziemnego Magazynu Gazu Wierzchowice. ",
      description_long: "no",
      icons: "",
    },
    {
      id: "cw13_pl",
      year: "2011 - 2012",
      status: "Zakończono",
      investor: "PGE El. Opole ",
      site: "Elektrownia Opole",
      unit: "Blok 360 MW Nr 2",
      description_short:
        "Rozruch bloku nr 2 o mocy 360 MW po modernizacji AKPiA. ",
      description_long: "no",
      icons: "",
    },
    {
      id: "cw12_pl",
      year: "2011",
      status: "Zakończono",
      investor: "AWBUD sp. z o.o. ",
      site: "Elektrociepłownia Wałcz",
      unit: "-",
      description_short: "Koordynacja końcowej fazy montażu, rozruch. ",
      description_long: "no",
      icons: "",
    },
    {
      id: "cw11_pl",
      year: "2011",
      status: "Zakończono",
      investor: "Vattenfall Heat Poland S.A.",
      site: "Elektrociepłownia Siekierki",
      unit: "Blok Nr 7 I 8",
      description_short:
        "Nadzór nad rozruchem, uruchomieniem i ruchem próbnym bloków 7 i 8. ",
      description_long: "no",
      icons: "",
    },
    {
      id: "cw10_pl",
      year: "2011",
      status: "Zakończono",
      investor: "Mostostal Zabrze Holding S.A. ",
      site: "Elektrownia Szczecin",
      unit: "Blok 50 Mw",
      description_short:
        "Koordynacja ostatniej fazy robót montażowych i nadzór nad rozruchem Bloku 50 MW. ",
      description_long: "no",
      icons: "",
    },
    {
      id: "cw09_pl",
      year: "2011",
      status: "Zakończono",
      investor: "PGE Polska Grupa Energetyczna S.A.",
      site: "Elektrownia Opole",
      unit: "Blok 360 Mw Nr 2",
      description_short:
        "Rozruch bloku nr 2 po modernizacji systemu automatyki. ",
      description_long: "no",
      icons: "",
    },
    {
      id: "cw08_pl",
      year: "2010",
      status: "Zakończono",
      investor: "Energomontaż Północ S.A. ",
      site: "Elektrociepłownia Wrocław ",
      unit: "Instalacja podawania biomasy",
      description_short:
        "Koordynacja i nadzór nad rozruchem instalacji podawania biomasy. ",
      description_long: "no",
      icons: "",
    },
    {
      id: "cw07_pl",
      year: "2010",
      status: "Zakończono",
      investor: "Soda Polska Ciech sp. z o.o.",
      site: "Elektrociepłownia Janikowo",
      unit: "Kocioł CKTI-75",
      description_short: "Rozruch kotła CKTI-75. ",
      description_long: "no",
      icons: "",
    },
    {
      id: "cw06_pl",
      year: "2010",
      status: "Zakończono",
      investor: "Alstom Power sp. z o.o.",
      site: "Elektrownia Bełchatów",
      unit: "Blok 858 MW",
      description_short: "Dmuchanie rurociągów parowych bloku 858 MW. ",
      description_long: "no",
      icons: "",
    },
    {
      id: "cw05_pl",
      year: "2010",
      status: "Zakończono",
      investor: "ZRE Katowice ",
      site: "Fortum Częstochowa",
      unit: "Blok 60 MW",
      description_short:
        "Nadzór nad rozruchem systemu nawęglania bloku 60 MW w Fortum Częstochowa. ",
      description_long: "no",
      icons: "",
    },
    {
      id: "cw04_pl",
      year: "2010",
      status: "Zakończono",
      investor: "Polimex-Mostostal S.A. ",
      site: "International Paper Kwidzyn",
      unit: "Turbozespół TG4",
      description_short: "Rozruch turbozespołu TG4.",
      description_long: "no",
      icons: "",
    },
    {
      id: "cw03_pl",
      year: "2010",
      status: "Zakończono",
      investor: "Fabryka Kotłów Sefako S.A. ",
      site: "PCC Rokita Brzeg Dolny",
      unit: "Kocioł OR-45",
      description_short: "Rozruch kotła OR-45.",
      description_long: "no",
      icons: "",
    },
    {
      id: "cw02_pl",
      year: "2010",
      status: "Zakończono",
      investor: "Emerson Process Management",
      site: "Elektrownia Opole",
      unit: "Blok 360 MW",
      description_short:
        "Pełnienie funkcji kierownika budowy oraz przeprowadzenie rozruchu zmodernizowanego systemu DCS",
      description_long: "no",
      icons: "",
    },
    {
      id: "cw01_pl",
      year: "2010",
      status: "Zakończono",
      investor: "PGE Górnictwo i Energetyka Konwencjonalna",
      site: "Elektrownia Opole",
      unit: "Blok 360 Mw",
      description_short:
        "Rozruch bloku nr 1 o mocy 360 MW w El. Opole po modernizacji AKPiA. ",
      description_long: "no",
      icons: "",
    },
  ],
  foreignWorks: [
    {
      id: "fw23_pl",
      year: "2020-2021",
      status: "Zakończono",
      investor: "Control Process S.A.",
      site: "Estonia, Paldiski i Puiatu",
      unit: "Tłocznie gazowe",
      description_short:
        "Projekt oraz rozruch tłoczni gazowych w Paldiski i Puiatu.",
      description_long: "no",
      icons: "",
    },
    {
      id: "fw22_pl",
      year: "2020",
      status: "Zakończono",
      investor: "NTPC - Sail Power Company",
      site: "Elektrownia Rourkela, Indie",
      unit: "Kocioł OPG-280",
      description_short:
        "Dostawa odgięć orurowania kotła do narożnych palników pyłowych",
      description_long: "no",
      icons: "",
    },
    {
      id: "fw21_pl",
      year: "2019",
      status: "Zakończono",
      investor: "Sutech S.R.L. (SSRL)",
      site: "Jupa, Caras Severin, Rumunia",
      unit: "Tłocznia gazu",
      description_short: "Kompleksowy rozruch Turbo Kompresora gazu",
      description_long: "no",
      icons: "",
    },
    {
      id: "fw20_pl",
      year: "2015 - 2019",
      status: "Zakończono",
      investor: "TAMEH Czech S.R.O.",
      site: "Ostrava, Czech Republic",
      unit: "Kocioł nr 8 ( 77 MWt)",
      description_short: "Modernizacja kotła K8 w celu redukcji emisji NOx",
      description_long: "no",
      icons: "",
    },
    {
      id: "fw19_pl",
      year: "2017",
      status: "Zakończono",
      investor: "Bokaro Power Supply Co.",
      site: "Elektrownia Bokaro, Indie",
      unit: "Kocioł OPG-260",
      description_short:
        "Projekt, dostawa i nadzór nad montażem wężownic przegrzewacza",
      description_long: "no",
      icons: "",
    },
    {
      id: "fw18_pl",
      year: "2015",
      status: "Zakończono",
      investor: "NTPC - Sail Power Company",
      site: "Elektrownia Rourkela, Indie",
      unit: "Kocioł OPG-280",
      description_short:
        "Dostawa kompletnego układu wirującego (wał, wirnik) do wentylatora ciągu typu WPWD-140",
      description_long: "no",
      icons: "",
    },
    {
      id: "fw17_pl",
      year: "2014",
      status: "Zakończono",
      investor: "EDS Gazpetro Poland sp. z o.o. ",
      site: "Ernei, Rumunia",
      unit: "Tłocznia gazu",
      description_short: "Rozruch agregatu sprężającego i urządzeń tłoczni gaz",
      description_long: "no",
      icons: "",
    },
    {
      id: "fw16_pl",
      year: "2013",
      status: "Zakończono",
      investor: "Rafako S.A. ",
      site: "Spalarnia Odpadów Kara Noveren",
      unit: "Blok KN6 OSR-95",
      description_short:
        "Wykonanie chemicznego czyszczenia oraz dmuchania kotła OSr-95 wraz z rozruchem ",
      description_long: "no",
      icons: "",
    },
    {
      id: "fw15_pl",
      year: "2013",
      status: "Zakończono",
      investor: "NTPC - Sail Power Company",
      site: "Elektrownia Rourkela Indie",
      unit: "Kocioł OPG-280",
      description_short:
        "Dostawa aparatów kierowniczych do wentylatora powietrza.",
      description_long: "no",
      icons: "",
    },
    {
      id: "fw14_pl",
      year: "2013",
      status: "Zakończono",
      investor: "Elektroprivreda Bosne i Hercegovine",
      site: "Elektrownia Tuzla",
      unit: "Turbina TG-215 MW",
      description_short: "Pomiary gwarancyjne turbiny TG-215 MW.",
      description_long: "no",
      icons: "",
    },
    {
      id: "fw13_pl",
      year: "2013",
      status: "Zakończono",
      investor: "EDS Gazpetro Poland sp. z o.o. ",
      site: "Delenii, Rumunia",
      unit: "Tłocznia Gazu",
      description_short:
        "Prace rozruchowe w zakresie czterech Tłoczni Gazowych.",
      description_long: "no",
      icons: "",
    },
    {
      id: "fw12_pl",
      year: "2013",
      status: "Zakończono",
      investor: "Bokaro Power Supply Co.",
      site: "Elektrownia Bokaro Indie",
      unit: "Turbina TG-66 MW",
      description_short:
        "Dostawa części zamiennych do urządzeń pomocniczych turbiny typu 9K66.",
      description_long: "no",
      icons: "",
    },
    {
      id: "fw11_pl",
      year: "2012",
      status: "Zakończono",
      investor: "Bokaro Power Supply Co.",
      site: "Elektrownia Bokaro, Indie",
      unit: "Kocioł OPG-260",
      description_short: "Dostawa schładzaczy pary SP-1 do kotła OPG-260. ",
      description_long: "no",
      icons: "",
    },
    {
      id: "fw10_pl",
      year: "2012",
      status: "Zakończono",
      investor: "Bokaro Power Supply Co.",
      site: "Elektrownia Bokaro, Indie",
      unit: "Kocioł OPG-260 nr 6",
      description_short:
        "Dostawa odgięć orurowania kotła do narożnych palników pyłowych. ",
      description_long: "no",
      icons: "",
    },
    {
      id: "fw09_pl",
      year: "2012",
      status: "Zakończono",
      investor: "Rafako S.A. ",
      site: "Elektrownia Nikola Tesla Serbia",
      unit: "Kocioł BB-1880 B1",
      description_short:
        "Badania, analizy, pomiary parametrów kotła BB-1880 B1 w celu zwiększenia sprawności kotła. ",
      description_long: "no",
      icons: "",
    },
    {
      id: "fw08_pl",
      year: "2012",
      status: "Zakończono",
      investor: "Rafako S.A. ",
      site: "Spalarnia Goch BMHK Niemcy",
      unit: "Kocioł Parowy OSR-23",
      description_short:
        "Nadzór nad rozruchem kotła parowego OSr-23 do spalania biomasy. ",
      description_long: "no",
      icons: "",
    },
    {
      id: "fw07_pl",
      year: "2012",
      status: "Zakończono",
      investor: "Gujarat State Electricity Co.",
      site: "Elektrownia Kutch, Indie",
      unit: "Kocioł OB-330",
      description_short:
        "Dostawa palników pyłowych oraz dysz palnikowych dla kotła 75 MW nr 3.",
      description_long: "no",
      icons: "",
    },
    {
      id: "fw06_pl",
      year: "2011",
      status: "Zakończono",
      investor: "Labolatorije Za Termotehniku i Enegetiku Institutu U Vinci",
      site: "Elektrownia Nikola Tesla Serbia",
      unit: "Kocioł BB-1850 ",
      description_short:
        "Badania i optymalizacja parametrów pracy kotła BB-1850 t/h i jego urządzeń pomocniczych. ",
      description_long: "no",
      icons: "",
    },
    {
      id: "fw05_pl",
      year: "2011",
      status: "Zakończono",
      investor: "Bokaro Power Supply Co.",
      site: "Elektrownia Bokaro Indie",
      unit: "Kocioł OPG-260",
      description_short:
        "Dostawa wężownic podgrzewacza wody dla kotła OPG-260.",
      description_long: "no",
      icons: "",
    },
    {
      id: "fw04_pl",
      year: "2011",
      status: "Zakończono",
      investor: "NTPC - Sail Power Company",
      site: "Elektrownia Durgapur, Indie",
      unit: "Kocioł OPG-260",
      description_short:
        "Dostawa części zapasowych dla przekładni hydraulicznych pomp wody zasilającej oraz zaworów trójdrożnych",
      description_long: "no",
      icons: "",
    },
    {
      id: "fw03_pl",
      year: "2011",
      status: "Zakończono",
      investor: "EDS Gazpetro Poland sp. z o.o. ",
      site: "Delenii, Rumunia",
      unit: "Tłocznia Gazu ",
      description_short:
        "Kompleksowy rozruch Tłoczni Gazu wraz z prowadzeniem eksploatacji wstępnej z koordynacją prac naprawczo- regulacyjnych.",
      description_long: "no",
      icons: "",
    },
    {
      id: "fw02_pl",
      year: "2011 - 2013",
      status: "Zakończono",
      investor: "Elektropriveda Bosne i Hercegovine",
      site: "Elektrociepłownia Tuzla",
      unit: "Kocioł OB-650 nr 6 215 MW",
      description_short:
        "Kompleksowa modernizacja i rewitalizacja kotła nr 6 o mocy 215 MW",
      description_long: "no",
      icons: "",
    },
    {
      id: "fw01_pl",
      year: "2010",
      status: "Zakończono",
      investor: "NTPC - Sail Power Company",
      site: "Elektrownia Rourkela Indie",
      unit: "Kocioł OPG-280",
      description_short:
        "Dostawa odgięć orurowania kotła do narożnych palników pyłowych ",
      description_long: "no",
      icons: "",
    },
  ],
};

export const referencesList_en = {
  modernizationWorks: [
    {
      id: "mw43_en",
      year: "2021",
      status: "In progress",
      investor: "PGE Energia Ciepła S.A.",
      site: "Heat & Power plant Bydgoszcz",
      unit: "4x 10 MW gas boilers ",
      description_short:
        "Comprehensive construction of a gas-fired heating plant with a capacity of up to 40 MW",
      description_long: "no",
      icons: "Gaz Kotłownia",
    },
    {
      id: "mw42_en",
      year: "2021 - 2022",
      status: "Completed",
      investor: "Węglokoks Energia ZCP",
      site: "Heat & Power plant Mikołaj",
      unit: "Gas boilers 2x 11 MW",
      description_short: "Comprehensive construction of gas boiler room",
      description_long: "no",
      icons: "Gaz Wodór Kotłownia",
    },
    {
      id: "mw41_en",
      year: "2021",
      status: "In progress",
      investor: "PGE Energia Ciepła S.A.",
      site: "Heat & Power plant Gdynia",
      unit: "3 oil-gas boilers (90 MWt)",
      description_short: "Comprehensive construction of gas boiler room",
      description_long: "no",
      icons: "Gaz Wodór Kotłownia",
    },
    {
      id: "mw40_en",
      year: "2021",
      status: "In progress",
      investor: "PGE Energia Ciepła S.A.",
      site: "Heat & Power plant Gdynia",
      unit: "26 MWt oil boiler ",
      description_short:
        "Installation of a steam boiler fired with light oil in the existing building",
      description_long: "no",
      icons: "Gaz Wodór Kotłownia",
    },
    {
      id: "mw39_en",
      year: "2020 - 2023",
      status: "Completed",
      investor: "PCC Rokita S.A.",
      site: "Brzeg Dolny",
      unit: "10 MW gas boiler",
      description_short:
        "Comprehensive construction of gas boiler room (hydrogen fuel)",
      description_long: "no",
      icons: "Gaz Wodór Kotłownia",
    },
    {
      id: "mw38_en",
      year: "2020",
      status: "In progress",
      investor: "Grupa Azoty S.A",
      site: "Zakłady Chemiczne Tarnów",
      unit: "TP-170G (K1) gas boiler",
      description_short:
        "Modernisation of Gas Boiler K1 in the Energy Centre EC II in Grupa Azoty S.A.",
      description_long: "no",
      icons: "Gaz Metoda pierwotna",
    },
    {
      id: "mw37_en",
      year: "2020 - 2022",
      status: "Completed",
      investor: "Energa Kogeneracja Sp. z o.o.",
      site: "Heat & Power plant Elbląg",
      unit: "3 gas boilers 38 MWt",
      description_short:
        "Execution of complex design and construction works, including supply, assembly, commissioning, regulation and test run",
      description_long: "no",
      icons: "Gaz Kotłownia",
    },
    {
      id: "mw36_en",
      year: "2019 - 2021",
      status: "Completed",
      investor: "PGE EC",
      site: "Heat & Power plant Gdańsk",
      unit: " WP-70 (K4) & WP-120 (K6) boilers",
      description_short:
        "Modernisation of combustion systems of Water Boilers using primary methods for the reduction of NOx concentration",
      description_long: "no",
      icons: "Metoda pierwotna",
    },
    {
      id: "mw35_en",
      year: "2019 - 2021",
      status: "Completed",
      investor: "PGE EC",
      site: "Heat & Power plant Wrocław",
      unit: "WP-120 (K3) & WP-120 (K5) boilers",
      description_short:
        "Modernisation of combustion systems of Water Boilers using primary methods for the reduction of NOx concentration",
      description_long: "no",
      icons: "Metoda pierwotna",
    },
    {
      id: "mw34_en",
      year: "2019 - 2021",
      status: "Completed",
      investor: "TAURON Wytwarzanie S.A.",
      site: "Power plant Jaworzno III",
      unit: "Unit no. 1, 3 i 5 (Op-650)",
      description_short:
        "Construction of SCR flue gas denitrification system (SCR)",
      description_long: "no",
      icons: "SCR",
    },
    {
      id: "mw33_en",
      year: "2018 - 2021",
      status: "Completed",
      investor: "VEOLIA Energia Łódź S.A.",
      site: "Heat & Power plant EC4 Łódź",
      unit: "OP-130 (K1 & K2), OP-230 (K3) boilers",
      description_short: "Construction of SCR system in boiler second pass",
      description_long: "no",
      icons: "SCR SNCR Hybryda",
    },
    {
      id: "mw32_en",
      year: "2018 - 2021",
      status: "Completed",
      investor: "VEOLIA Energia Łódź S.A.",
      site: "Heat & Power plant EC4 Łódź",
      unit: "OP-230 (K3), OP-430 (K7) boilers",
      description_short: "Construction of SCR system in boiler second pass",
      description_long: "no",
      icons: "SCR SNCR Hybryda",
    },
    {
      id: "mw31_en",
      year: "2019-2020",
      status: "Completed",
      investor: "PEC Gliwice sp. z o.o. ",
      site: "PEC Gliwice",
      unit: "WP70 nr 2 boiler 81,4 MWt ",
      description_short:
        "Construction of flue gas denitrification system of Boiler WP-70 by non-catalytic SNCR method",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw30_en",
      year: "2018-2019 ",
      status: "Completed",
      investor: "PEC Gliwice sp. z o.o. ",
      site: "PEC Gliwice",
      unit: "WP70 nr 2 boiler 81,4 MWt ",
      description_short:
        "Overhaul and replacement of the complete pressure part of the WP-70 boiler",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw29_en",
      year: "2018-2019 ",
      status: "Completed",
      investor: "VEOLIA Energia Łódź S.A.",
      site: "Heat & Power plant EC4 Łódź",
      unit: "Boiler WP120 no. 6 ",
      description_short:
        "Construction of deNOx system reducing nitrogen oxides emission to 430 mg/ Nm3 by primary method.",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw28_en",
      year: "2017- 2018 ",
      status: "Completed",
      investor: "Energetyka sp. z o.o. Lublin",
      site: "Heat & Power plant EC3 Głogów",
      unit: "2 gas boilers 33 MWt ",
      description_short:
        "Expansion of capacity based on Gas Boilers of approx. 60 MWt",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw27_en",
      year: "2017-2019 ",
      status: "Completed",
      investor: "PEC Gliwice sp. z o.o. ",
      site: "PEC Gliwice",
      unit: "WP70 no. 1 & 3  boilers 81,4 MWt",
      description_short:
        "Construction of flue gas denitrification system of Boiler WP-70 by non-catalytic SNCR method.",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw26_en",
      year: "2016-2017 ",
      status: "Completed",
      investor: "PGE Energia Ciepła S.A.",
      site: "Power plant Rybnik ",
      unit: "Boiler OP-650 (K3 225 MW, K5 215 MW, K6 215 MW)",
      description_short:
        "Modernization of OP- 650k boilers for reduction of NOx level to 200 mg/ Nm3",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw25_en",
      year: "2016-2018 ",
      status: "Completed",
      investor: "Heat & Power plant AMP Kraków",
      site: "Heat & Power plant AMP Kraków",
      unit: "Boiler OP-230 no. 8 ",
      description_short:
        "Reconstruction of OP-230 boiler in order to reduce emissions of gaseous and dust pollutants",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw24_en",
      year: "2016-2017 ",
      status: "Completed",
      investor: "Grupa Azoty S.A",
      site: "Heat & Power plant ECII Tarnów",
      unit: "Boiler OP-230 no. 5  160 MW ",
      description_short:
        "Comprehensive flue gas denitrification system to achieve NOx emissions <190 mg/Nm3",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw23_en",
      year: "2015 -2019 ",
      status: "Completed",
      investor: "VEOLIA Energia Łódź S.A.",
      site: "Heat & Power plant EC4 Łódź",
      unit: "Boiler OP-230 no. 2 ",
      description_short:
        "Modernisation of the combustion system in order to reduce NOx emissions of 190 mg/Nm3",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw22_en",
      year: "2015 -2019",
      status: "Completed",
      investor: "VEOLIA Energia Łódź S.A.",
      site: "Heat & Power plant EC3 Łódź",
      unit: "Boiler OP-130 no. 1,2  & OP-230 no. 3  ",
      description_short:
        "Modernisation of the combustion system in order to reduce NOx emissions of 190 mg/Nm3",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw21_en",
      year: "2015-2018 ",
      status: "Completed",
      investor: "PGE Energia Ciepła S.A.",
      site: "Power plant Rybnik ",
      unit: "Boilers OP-650 (225 MWe K3 215 MWe K5 215 MWe K6)",
      description_short:
        "Modernisation of the combustion system to achieve NOx emission levels ≤ 300 mg/ Nm3",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw20_en",
      year: "2015-2016 ",
      status: "Completed",
      investor: "PGE Energia Ciepła S.A.",
      site: "Power plant Rybnik ",
      unit: "Boilers OP-650 (215 MWe K5 225 MWe K8)",
      description_short:
        "Modernisation of the combustion system to achieve NOx emission levels ≤ 300 mg/ Nm3",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw19_en",
      year: "2015-2016",
      status: "Completed",
      investor: "PGE Energia Ciepła S.A.",
      site: "Power plant Rybnik ",
      unit: "Boiler OP-650 no. 3 225 MW ",
      description_short:
        "Modernisation of the combustion system to achieve NOx emission levels ≤ 300 mg/ Nm3",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw18_en",
      year: "2014",
      status: "Completed",
      investor: "PEC Gliwice sp. z o.o. ",
      site: "PEC GLIWICE ",
      unit: "Boiler WP-70 no. 3 81,4 MWt ",
      description_short:
        "Renovation of flue gas denitrification installation of boiler WP-70 ",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw17_en",
      year: "2013",
      status: "Completed",
      investor: "PGE Górnictwo i Energetyka Konwencjonalna",
      site: "Power plant Dolna Odra ",
      unit: "Boiler OP-650 no. 5 & 8 222 MW K5 232 MW K8 ",
      description_short:
        "Construction of complete flue gas denitrification systems to reduce NOx emissions to ≤190 mg/Nm3 using primary and secondary (SNCR) methods",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw16_en",
      year: "2014-2015 ",
      status: "Completed",
      investor: "TAURON Wytwarzanie S.A.",
      site: "Power plant Jaworzno III",
      unit: "CFB 260 boilers",
      description_short:
        "Adaptation of CFB 260 boilers to the requirements of emission standards",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw15_en",
      year: "2013",
      status: "Completed",
      investor: "PGE Górnictwo i Energetyka Konwencjonalna",
      site: "Power plant Dolna Odra ",
      unit: "Boiler OP-650 ",
      description_short:
        "Modernisation of coal mill dust separators type MKM25",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw14_en",
      year: "2013",
      status: "Completed",
      investor: "Gmina Lubusko",
      site: "Sewage treatment plant",
      unit: "-",
      description_short:
        "Construction, supply and commissioning of the Wastewater Treatment Plant ",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw13_en",
      year: "2013",
      status: "Completed",
      investor: "PGNiG Termika S.A. ",
      site: "Heat & Power plant Siekierki, Warszawa ",
      unit: "Boiler WP-120 no. 5, 6 & 7 WP-200, K-16 ",
      description_short: "Modernisation of combustion system of WP-120 boilers",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw12_en",
      year: "2013 - 2015 ",
      status: "Completed",
      investor: "Dalkia Łódź S.A.",
      site: "Heat & Power plant Łódź",
      unit: "Boiler OP-430K no. 7 ",
      description_short:
        "Comprehensive modernisation of combustion system reducing emissions of nitrogen oxides to the level of NOx <190 mg/Nm3",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw11_en",
      year: "2013-2017",
      status: "Completed",
      investor: "Zakłady Chemiczne Police S.A.       ",
      site: "Heat & Power plant Police II",
      unit: "Boiler OP-230 no. 1 & 2",
      description_short:
        "Modernization of OP-230 boilers to reduce emissions of NOx ≤190 mg/Nm3",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw10_en",
      year: "2012-2013 ",
      status: "Completed",
      investor: "PGE Górnictwo i Energetyka Konwencjonalna",
      site: "Power plant Dolna Odra ",
      unit: "Boiler OP-650 no 6 & 7 225 MW K6 232 MW K7 ",
      description_short:
        "Modernisation of OP-650 boilers to reduce emissions of NOx ≤190 mg/ Nm3",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw09_en",
      year: "2012-2015 ",
      status: "Completed",
      investor: "PGE Energia Ciepła S.A.",
      site: "Power plant Rybnik ",
      unit: "Boiler OP-650 nr 5 & 8  215 MW K5 225 MW K8  ",
      description_short:
        "Modernization of combustion system of OP-250 boilers to achieve NOx emission <300 mg/Nm3",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw08_en",
      year: "2012",
      status: "Completed",
      investor: "Enea Wytwarzanie S.A.",
      site: "Power plant Kozienice ",
      unit: "Boiler OP-650 no. 3 223 MW ",
      description_short:
        "Modernization of PF burners in order to reduce NOx emission to the level below 300 mg/ Nm3 ",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw07_en",
      year: "2011",
      status: "Completed",
      investor: "PGE Energia Ciepła S.A.",
      site: "Heat & Power plant  Gdańsk ",
      unit: "Boiler OP-230 no. 10 165 MW ",
      description_short:
        "Comprehensive modernisation of the combustion system of K10 boiler",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw06_en",
      year: "2011",
      status: "Completed",
      investor: "Grupa GDF Suez Energia Polska",
      site: "Power plant Połaniec ",
      unit: "Boiler EP-650 no. 5 225 MW ",
      description_short:
        "Modernization of combustion system of boiler EP-650 to achieve NOx emission of 200 mg/Nm3",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw05_en",
      year: "2011",
      status: "Completed",
      investor: "PGE Energia Ciepła S.A.",
      site: "Power plant Rybnik",
      unit: "Boiler OP-650 (K7) 212 MWe",
      description_short:
        "Modernization of combustion system to achieve the required emission level of NOx ≤ 300 mg/Nm3 ",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw04_en",
      year: "2010",
      status: "Completed",
      investor: "Alstom Power sp. z o.o. ",
      site: "Power plant Bełchatów",
      unit: "Boiler BB-2400 (Unit 860 MW)",
      description_short:
        "Design, supply of auxiliary installation and execution of BB-2400 Boiler blowdown process and HP pipelines",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw03_en",
      year: "2010",
      status: "Completed",
      investor: "Grupa GDF Suez Energia Polska",
      site: "Power plant Połaniec",
      unit: "Boiler OP-650 (K8)",
      description_short:
        "Application of new combustion technology with NOx emission reduction <200 mg/Nm3",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw02_en",
      year: "2010",
      status: "Completed",
      investor: "Energa Kogeneracja sp. z o.o.",
      site: "Heat & Power plant Elbląg",
      unit: "Biomass Unit 20MW",
      description_short:
        "Contract Engineer for the Construction Project of 20 MW Biomass Power Unit",
      description_long: "no",
      icons: "",
    },
    {
      id: "mw01_en",
      year: "2010",
      status: "Completed",
      investor: "PGE Energia Ciepła S.A.",
      site: "Power plant Rybnik",
      unit: "Boiler OP-650 (K6) 215 MW",
      description_short:
        "Modernization of combustion system of boiler to achieve the required emission level of NOx ≤ 300 mg/Nm3 ",
      description_long: "no",
      icons: "",
    },
  ],
  commissioningWorks: [
    {
      id: "cw42_en",
      year: "2022",
      status: "In progress",
      investor: "Primue Energoserwis",
      site: "MEGATEM EC-Lublin",
      unit: "Boiler 45,6 MW / Turbine",
      description_short:
        "Technological commissioning of a biomass-fired unit with a fluidized bed boiler",
      description_long: "",
      icons: "",
    },
    {
      id: "cw41_pl",
      year: "2022",
      status: "In progress",
      investor: "RAFAKO S.A.",
      site: "JSW KOKS S.A.",
      unit: "Thermal Power Plant Radlin",
      description_short:
        "Development of commissioning documentation and execution of commissioning of a coke gas-fired unit",
      description_long: "",
      icons: "",
    },
    {
      id: "cw40_en",
      year: "2022",
      status: "Completed",
      investor: "SEFAKO",
      site: "Zakłady Azotowe Puławy",
      unit: "Boiler OP-215",
      description_short:
        "Coordination of the final phase of the boiler assembly in all branches",
      description_long: "",
      icons: "",
    },
    {
      id: "cw39_en",
      year: "2022",
      status: "Completed",
      investor: "Gaz-System S.A.",
      site: "Goleniów",
      unit: "Gas compressor station",
      description_short: "Expert for conducting commissioning",
      description_long: "",
      icons: "",
    },
    {
      id: "cw38_en",
      year: "2022",
      status: "In progress",
      investor: "Gaz-System S.A.",
      site: "Odolanów",
      unit: "Gas compressor station",
      description_short: "Expert for conducting commissioning",
      description_long: "",
      icons: "",
    },
    {
      id: "cw37_en",
      year: "2022",
      status: "In progress",
      investor: "SGS Polska",
      site: "Gustorzyn",
      unit: "Gas compressor station",
      description_short:
        "Supervision of the commissioning of the compressor station, technical and operational support of the compressor station operation",
      description_long: "",
      icons: "",
    },
    {
      id: "cw36_en",
      year: "2022",
      status: "Completed",
      investor: "MAX STREICHER SPA",
      site: "Gustorzyn",
      unit: "Gas compressor station",
      description_short:
        "Investor supervision during the construction of the compressor station, installation of fittings in the mechanical, I&C branches and control system",
      description_long: "",
      icons: "",
    },
    {
      id: "cw35_en",
      year: "2022",
      status: "Completed",
      investor: "Gas Control Polska",
      site: "Kędzierzyn Koźle",
      unit: "Gas compressor station",
      description_short:
        "Support of Investor's Supervision, coordination of commissioning works and conducting commissioning of the gas compressor station ",
      description_long: "",
      icons: "",
    },
    {
      id: "cw34_en",
      year: "2021",
      status: "In progress",
      investor: "Geotermia Toruń",
      site: "Toruń",
      unit: "Geothermal Power Plant",
      description_short:
        "Specialized and comprehensive operation services of the Geothermal Power Plant together with the associated Infrastructure",
      description_long: "",
      icons: "",
    },
    {
      id: "cw33_en",
      year: "2021",
      status: "In progress",
      investor: "Nowe Jaworzno Grupa TAURON",
      site: "Power plant Jaworzno III",
      unit: "Unit 910 MW",
      description_short:
        "Provision of the specialist operational and coordination services during Transition Period of  910 MW Power Unit",
      description_long: "",
      icons: "",
    },
    {
      id: "cw32_en",
      year: "2018",
      status: "In progress",
      investor: "E003B7 Sp. z o.o. Racibórz",
      site: "Power plant Jaworzno III",
      unit: "Unit 910 MW",
      description_short: "Comprehensive commissioning of the 910 MW Power Unit",
      description_long: "",
      icons: "",
    },
    {
      id: "cw31_en",
      year: "2015 - 2016",
      status: "Completed",
      investor: "EDF Polska",
      site: "Power plant Rybnik",
      unit: "Boiler OP-650 (K8)",
      description_short:
        "Comprehensive commissioning of the system after modernisation of boiler",
      description_long: "",
      icons: "",
    },
    {
      id: "cw30_en",
      year: "2015",
      status: "Completed",
      investor: "Siemens",
      site: "Heat & Power plant Gorzów",
      unit: "Steam & Gas Unit (140 MW)",
      description_short: "Comprehensive commissioning of Steam & Gas Unit",
      description_long: "",
      icons: "",
    },
    {
      id: "cw29_en",
      year: "2014 - 2017",
      status: "Completed",
      investor: "SNC LAVALIN",
      site: "Anwil Włocławek",
      unit: "Steam & Gas Unit (460 MW)",
      description_short: "Commissioning of the Gas-Steam Unit",
      description_long: "",
      icons: "",
    },
    {
      id: "cw28_en",
      year: "2014 - 2016",
      status: "Completed",
      investor: "Rafako S.A.",
      site: "GDF SUEZ Power plant Połaniec",
      unit: "Boiler OP-650 (K6)",
      description_short:
        "Commissioning of flue gas catalytic denitrification unit ",
      description_long: "",
      icons: "",
    },
    {
      id: "cw27_en",
      year: "2014 - 2016",
      status: "Completed",
      investor: "Fluor S.A",
      site: "BASF Catalyst Plant Środa Śląska",
      unit: "-",
      description_short: "Participation in the commissioning",
      description_long: "no",
      icons: "",
    },
    {
      id: "cw26_en",
      year: "2014",
      status: "Completed",
      investor: "Energomontaż Zachód Wrocław",
      site: "Energa Power plant Ostrołęka",
      unit: "Turbine 13K200",
      description_short: "Commissioning of New Heat Source",
      description_long: "no",
      icons: "",
    },
    {
      id: "cw25_en",
      year: "2013",
      status: "Completed",
      investor: "Fabryka Kotłów Sefako S.A. ",
      site: "PCC Rokita Brzeg Dolny",
      unit: "Boiler OR-45 K1 & K2",
      description_short:
        "Comprehensive technological commissioning of the Boilers  K-1 and K-2 with auxiliary equipment",
      description_long: "no",
      icons: "",
    },
    {
      id: "cw24_en",
      year: "2013 - 2014",
      status: "Completed",
      investor: "Rafako S.A. ",
      site: "Energa Power plant Kozienice",
      unit: "Boiler Op-650 no. 6",
      description_short:
        "Commissioning of the system without SCR - unit part and commissioning of the complete SCR installation ",
      description_long: "no",
      icons: "",
    },
    {
      id: "cw23_en",
      year: "2013 - 2015",
      status: "Completed",
      investor: "PGNiG Warszawa ",
      site: "Underground Gas Storage Wierzchowice",
      unit: "1200 m3 gas storage",
      description_short:
        "Substitute performance - completion of the Underground Gas Storage construction",
      description_long: "no",
      icons: "",
    },
    {
      id: "cw22_en",
      year: "2013",
      status: "Completed",
      investor: "Gmina Lubsko ",
      site: "Waste treatment plant",
      unit: "-",
      description_short:
        "Construction works, supply and commissioning of the  waste treatment plant",
      description_long: "no",
      icons: "",
    },
    {
      id: "cw21_en",
      year: "2012",
      status: "Completed",
      investor: "Tauron Wytwarzanie ",
      site: "Power plant Jaworzno III",
      unit: "OZE 50 MW",
      description_short:
        "Comprehensive commissioning of a 50 MW RES Generating Unit",
      description_long: "no",
      icons: "",
    },
    {
      id: "cw20_en",
      year: "2012",
      status: "Completed",
      investor: "ZRE Katowice ",
      site: "Power plant Jaworzno II",
      unit: "TG-1 50 MW",
      description_short: "Commissioning of TG-1 Turbine of 50 MW power",
      description_long: "no",
      icons: "",
    },
    {
      id: "cw19_en",
      year: "2012",
      status: "Completed",
      investor: "Rafako S.A. ",
      site: "Power plant Jaworzno II",
      unit: "Unit 50 MW Boiler CFB/OFZ-201",
      description_short:
        "Supervision of final assembly of equipment, boiler cold and hot start-up",
      description_long: "no",
      icons: "",
    },
    {
      id: "cw18_en",
      year: "2012 - 2013",
      status: "Completed",
      investor: "Foster Wheeler ",
      site: "Power plant Połaniec",
      unit: "-",
      description_short:
        "Acting as Shift Supervisors in the commissioning of the biomass boiler",
      description_long: "no",
      icons: "",
    },
    {
      id: "cw17_en",
      year: "2012 - 2013",
      status: "Completed",
      investor: "Tauron Wytwarzanie ",
      site: "Heat & Power plant Bielsko-Biała (Ec1)",
      unit: "Unit BC-50",
      description_short: "Coordination of commissioning of Power Unit BC-50",
      description_long: "no",
      icons: "",
    },
    {
      id: "cw16_en",
      year: "2011 - 2012",
      status: "Completed",
      investor: "Tecnimont S.P.A Oddział W Polsce",
      site: "Underground Gas Storage Wierzchowice",
      unit: "1200 m3 gas storage",
      description_short:
        "Participation in the commissioning of the energy part of the Underground Gas Storage",
      description_long: "no",
      icons: "",
    },
    {
      id: "cw15_en",
      year: "2012",
      status: "Completed",
      investor: "Grupa GDF Suez Energia Polska ",
      site: "Power plant Połaniec",
      unit: "Green block no. 8/205 MW Boiler CFB-447 ",
      description_short:
        "Comprehensive coordination of the commissioning of the GREEN BLOCK 205 MW No. 8 and the CFB-447 MWt Boiler",
      description_long: "no",
      icons: "",
    },
    {
      id: "cw14_en",
      year: "2011 - 2012",
      status: "Completed",
      investor: "PBG S.A. ",
      site: "Underground Gas Storage Wierzchowice",
      unit: "1200 m3 gas storage",
      description_short:
        "Coordinating and managing the commissioning of the Wierzchowice Underground Gas Storage Facility",
      description_long: "no",
      icons: "",
    },
    {
      id: "cw13_en",
      year: "2011 - 2012",
      status: "Completed",
      investor: "PGE El. Opole ",
      site: "Power plant Opole",
      unit: "Unit 360 MW no. 2",
      description_short:
        "Commissioning of 360 MW Unit No. 2 at Opole Power Plant after modernisation of Control and Measurement Instruments and Automation",
      description_long: "no",
      icons: "",
    },
    {
      id: "cw12_en",
      year: "2011",
      status: "Completed",
      investor: "AWBUD sp. z o.o. ",
      site: "Heat & Power plant Wałcz",
      unit: "-",
      description_short:
        "Coordination of the final assembly phase, commissioning",
      description_long: "no",
      icons: "",
    },
    {
      id: "cw11_en",
      year: "2011",
      status: "Completed",
      investor: "Vattenfall Heat Poland S.A.",
      site: "Heat & Power plant Siekierki",
      unit: "Unit no 7 & 8",
      description_short:
        "Supervision over commissioning and trail run of Units 7 & 8",
      description_long: "no",
      icons: "",
    },
    {
      id: "cw10_en",
      year: "2011",
      status: "Completed",
      investor: "Mostostal Zabrze Holding S.A. ",
      site: "Power plant Szczecin",
      unit: "Unit 50 MW",
      description_short:
        "Coordination of the last phase of the assembly works and supervision of the commissioning of 50 MW Unit",
      description_long: "no",
      icons: "",
    },
    {
      id: "cw09_en",
      year: "2011",
      status: "Completed",
      investor: "PGE Polska Grupa Energetyczna S.A.",
      site: "Power plant Opole",
      unit: "Unit 360 MW No 2",
      description_short:
        "Commissioning of Power Unit No. 2 after modernization of the Control and Measurement Instruments and Automation",
      description_long: "no",
      icons: "",
    },
    {
      id: "cw08_en",
      year: "2010",
      status: "Completed",
      investor: "Energomontaż Północ S.A. ",
      site: "Heat & Power plant Wrocław ",
      unit: "Biomass feeding installationy",
      description_short:
        "Coordination and supervision of the commissioning of the biomass feeding installation",
      description_long: "no",
      icons: "",
    },
    {
      id: "cw07_en",
      year: "2010",
      status: "Completed",
      investor: "Soda Polska Ciech sp. z o.o.",
      site: "Heat & Power plant Janikowo",
      unit: "Boiler CKTI-75",
      description_short: "Commissioning of boiler CKTI-75",
      description_long: "no",
      icons: "",
    },
    {
      id: "cw06_en",
      year: "2010",
      status: "Completed",
      investor: "Alstom Power sp. z o.o.",
      site: "Power plant Bełchatów",
      unit: "Unit 858 MW",
      description_short: "Blowing of steam pipelines of 858 MW Unit",
      description_long: "no",
      icons: "",
    },
    {
      id: "cw05_en",
      year: "2010",
      status: "Completed",
      investor: "ZRE Katowice ",
      site: "Fortum Częstochowa",
      unit: "Unit 60 MW",
      description_short:
        "Supervision over the commissioning of a carbonizing system for a 60 MW Unit",
      description_long: "no",
      icons: "",
    },
    {
      id: "cw04_en",
      year: "2010",
      status: "Completed",
      investor: "Polimex-Mostostal S.A. ",
      site: "International Paper Kwidzyn",
      unit: "Turbineset TG4",
      description_short: "Commissioning of turbineset TG4 (SST 6001 26,7 MW)",
      description_long: "no",
      icons: "",
    },
    {
      id: "cw03_en",
      year: "2010",
      status: "Completed",
      investor: "Fabryka Kotłów Sefako S.A. ",
      site: "PCC Rokita Brzeg Dolny",
      unit: "Boiler OR-45",
      description_short: "Commissioning of OR-45 Boiler",
      description_long: "no",
      icons: "",
    },
    {
      id: "cw02_en",
      year: "2010",
      status: "Completed",
      investor: "Emerson Process Management",
      site: "Power plant Opole",
      unit: "Unit 360 MW",
      description_short:
        "Acting as Site Manager and leading of the commissioning of the modernised DCS and SIS protection system",
      description_long: "no",
      icons: "",
    },
    {
      id: "cw01_en",
      year: "2010",
      status: "Completed",
      investor: "PGE Górnictwo i Energetyka Konwencjonalna",
      site: "Power plant Opole",
      unit: "Unit 360 MW",
      description_short:
        "Commissioning of 360 MW Unit No. 1 at Opole Power Plant after modernisation of Control and Measurement Instruments and Automation",
      description_long: "no",
      icons: "",
    },
  ],
  foreignWorks: [
    {
      id: "fw23_pl",
      year: "2020-2021",
      status: "Completed",
      investor: "Control Process S.A.",
      site: "Estonia, Paldiski i Puiatu",
      unit: "Gas compressor stations",
      description_short:
        "Development of design and performance of technological commissioning at all stages",
      description_long: "no",
      icons: "",
    },
    {
      id: "fw22_pl",
      year: "2020",
      status: "Completed",
      investor: "NTPC - Sail Power Company",
      site: "Power plant Rourkela, Indie",
      unit: "Boiler OPG-280",
      description_short:
        "Supply of 4 pcs. of the deflection panels of boiler piping for the corner PF burners",
      description_long: "no",
      icons: "",
    },
    {
      id: "fw21_pl",
      year: "2019",
      status: "Completed",
      investor: "Sutech S.R.L. (SSRL)",
      site: "Jupa, Caras Severin, Rumunia",
      unit: "Gas Compressor Station",
      description_short:
        "Comprehensive commissioning of the Turbo Gas Compressor for all branches",
      description_long: "no",
      icons: "",
    },
    {
      id: "fw20_pl",
      year: "2015 - 2019",
      status: "Completed",
      investor: "TAMEH Czech S.R.O.",
      site: "Ostrava, Czech Republic",
      unit: "Boiler no.8 ( 77 MWt)",
      description_short:
        "Modernization of boiler K8 to reduce NOx emission. Emission level of NOx <200 mg/Nm3",
      description_long: "no",
      icons: "",
    },
    {
      id: "fw19_pl",
      year: "2017",
      status: "Completed",
      investor: "Bokaro Power Supply Co.",
      site: "Power plant Bokaro, Indie",
      unit: "Boiler OPG-260",
      description_short:
        "Design, supply and supervision over the installation of the boiler and superheater panels",
      description_long: "no",
      icons: "",
    },
    {
      id: "fw18_pl",
      year: "2015",
      status: "Completed",
      investor: "NTPC - Sail Power Company",
      site: "Power plant Rourkela, Indie",
      unit: "Boiler OPG-280",
      description_short:
        "Supply of the complete rotary system ( shaft, rotor) for the ID fan WPWD-140",
      description_long: "no",
      icons: "",
    },
    {
      id: "fw17_pl",
      year: "2014",
      status: "Completed",
      investor: "EDS Gazpetro Poland sp. z o.o. ",
      site: "Ernei, Rumunia",
      unit: "Gas Compressor Station",
      description_short:
        "Commissioning of the Gas Compressor Station, i.e. the compressor unit and its ancillary equipment",
      description_long: "no",
      icons: "",
    },
    {
      id: "fw16_pl",
      year: "2013",
      status: "Completed",
      investor: "Rafako S.A. ",
      site: "Spalarnia Odpadów Kara Noveren",
      unit: "Unit KN6 OSR-95",
      description_short:
        "Chemical cleaning and blowing of  OSr-95 Boiler with commissioning of the entire Rafako delivery",
      description_long: "no",
      icons: "",
    },
    {
      id: "fw15_pl",
      year: "2013",
      status: "Completed",
      investor: "NTPC - Sail Power Company",
      site: "Power plant Rourkela Indie",
      unit: "Boiler OPG-280",
      description_short: "Warranty measurements of the TG-215 MW Turbine",
      description_long: "no",
      icons: "",
    },
    {
      id: "fw14_pl",
      year: "2013",
      status: "Completed",
      investor: "Elektroprivreda Bosne i Hercegovine",
      site: "Power plant Tuzla",
      unit: "Turbine TG-215 MW",
      description_short: "Warranty measurements of the TG-215 MW Turbine",
      description_long: "no",
      icons: "",
    },
    {
      id: "fw13_pl",
      year: "2013",
      status: "Completed",
      investor: "EDS Gazpetro Poland sp. z o.o. ",
      site: "Delenii, Rumunia",
      unit: "Gas Compressor Stations",
      description_short: "Commissioning of four Gas Compressor Stations",
      description_long: "no",
      icons: "",
    },
    {
      id: "fw12_pl",
      year: "2013",
      status: "Completed",
      investor: "Bokaro Power Supply Co.",
      site: "Power plant Bokaro Indie",
      unit: "Turbine TG-66 MW",
      description_short:
        "Delivery of spare parts for auxiliary equipment of the turbine type 9K66",
      description_long: "no",
      icons: "",
    },
    {
      id: "fw11_pl",
      year: "2012",
      status: "Completed",
      investor: "Bokaro Power Supply Co.",
      site: "Power plant Bokaro, Indie",
      unit: "Boiler OPG-260",
      description_short:
        "Delivery of 4 pcs. of deflection panels  for PF burners",
      description_long: "no",
      icons: "",
    },
    {
      id: "fw10_pl",
      year: "2012",
      status: "Completed",
      investor: "Bokaro Power Supply Co.",
      site: "Power plant Bokaro, Indie",
      unit: "Boiler OPG-260 no. 6",
      description_short:
        "Delivery of 4 pcs. of steam coolers SP-1 for the Boiler",
      description_long: "no",
      icons: "",
    },
    {
      id: "fw09_pl",
      year: "2012",
      status: "Completed",
      investor: "Rafako S.A. ",
      site: "Power plant Nikola Tesla Serbia",
      unit: "Boiler BB-1880 B1",
      description_short:
        "Tests, analyses, measurements of boiler parameters BB-1880 B1 to increase boiler efficiency",
      description_long: "no",
      icons: "",
    },
    {
      id: "fw08_pl",
      year: "2012",
      status: "Completed",
      investor: "Rafako S.A. ",
      site: "Goch BMHK Niemcy",
      unit: "Boiler OSR-23",
      description_short:
        "Supervision of the commissioning of the OSr-23 Steam Boiler for biomass combustion",
      description_long: "no",
      icons: "",
    },
    {
      id: "fw07_pl",
      year: "2012",
      status: "Completed",
      investor: "Gujarat State Electricity Co.",
      site: "Power plant Kutch, Indie",
      unit: "Boiler OB-330",
      description_short:
        "Supply of PF burners and burner nozzles for 75 MW Boiler No.3",
      description_long: "no",
      icons: "",
    },
    {
      id: "fw06_pl",
      year: "2011",
      status: "Completed",
      investor: "Labolatorije Za Termotehniku i Enegetiku Institutu U Vinci",
      site: "Power plant Nikola Tesla Serbia",
      unit: "Boiler BB-1850 ",
      description_short:
        "Tests and optimisation of the operational parameters of the BB-1850 t/h Boiler and its auxiliaries",
      description_long: "no",
      icons: "",
    },
    {
      id: "fw05_pl",
      year: "2011",
      status: "Completed",
      investor: "Bokaro Power Supply Co.",
      site: "Power plant Bokaro Indie",
      unit: "Boiler OPG-260",
      description_short: "Delivery of economizer for the OPG-260 Boiler",
      description_long: "no",
      icons: "",
    },
    {
      id: "fw04_pl",
      year: "2011",
      status: "Completed",
      investor: "NTPC - Sail Power Company",
      site: "Power plant Durgapur, Indie",
      unit: "Boiler OPG-260",
      description_short:
        "Supply of spare parts for hydraulic couplings of feed water pumps, three-way valves for the HP regeneration system",
      description_long: "no",
      icons: "",
    },
    {
      id: "fw03_pl",
      year: "2011",
      status: "Completed",
      investor: "EDS Gazpetro Poland sp. z o.o. ",
      site: "Delenii, Rumunia",
      unit: "Gas Compressor Station",
      description_short:
        "Comprehensive commissioning of the Gas Compressor Station, including pre-operation with coordination of repair ",
      description_long: "no",
      icons: "",
    },
    {
      id: "fw02_pl",
      year: "2011 - 2013",
      status: "Completed",
      investor: "Elektropriveda Bosne i Hercegovine",
      site: "Heat & Power plant Tuzla",
      unit: "Boiler OB-650 no. 6 215 MW",
      description_short:
        "Comprehensive modernisation and revitalisation of the 215 MW Boiler No. 6",
      description_long: "no",
      icons: "",
    },
    {
      id: "fw01_pl",
      year: "2010",
      status: "Completed",
      investor: "NTPC - Sail Power Company",
      site: "Power plant Rourkela Indie",
      unit: "Boiler OPG-280",
      description_short:
        "Supply of deflection panels of the boiler piping for the corner PF burners ",
      description_long: "no",
      icons: "",
    },
  ],
};
